$ = jQuery;
$(document).ready(function () {

    const stickyElm = document.querySelector('header.sticky-top');

    if (stickyElm) {
        const observer = new IntersectionObserver(
            ([e]) => e.target.classList.toggle('isSticky', e.intersectionRatio < 1),
            { threshold: [1] }
        );
        observer.observe(stickyElm);
    }

    function getMatrix(element) {
        const values = element.style.transform.split(/\w+\(|\);?/);
        const transform = values[1] ? values[1].split(/,\s?/g).map(Number) : [0, 0, 0];

        return {
            x: transform[0] || 0,
            y: transform[1] || 0,
            z: transform[2] || 0
        };
    }
 
    // when .mega-menu-megamenu  is hover add blur everything except #main-nav-wrapper

    $('.mega-menu-megamenu').hover(
        function () {
            $('#blur-overlay').css('opacity', '1');
            $('#blur-overlay').css('display', 'block');
            $('#main-nav-wrapper').hadClass('shadow-lg');
            $('#blur-overlay').css('transition-delay', '0ms');

        },
        function () {
            $('#blur-overlay').css('opacity', '0');
            $('#blur-overlay').css('display', 'none');
            $('#main-nav-wrapper').removeClass('shadow');
            $('#blur-overlay').css('transition-delay', '200ms');


        }
    );

    // const parallaxFast = document.querySelectorAll('.parallax-scroll');
    // parallaxFast.forEach(element => {
    //     window.addEventListener('scroll', function () {
    //         var speed = element.getAttribute('data-speed');
    //         var scrollPosition = window.scrollY - element.getBoundingClientRect().top;
    //         var translateY = scrollPosition * (-0.005 * speed); // Adjust the scroll speed as needed
    //         var marginBottom = scrollPosition * (-0.003 * speed); // Adjust the margin-bottom value as needed
    //         element.style.transform = 'translateY(' + translateY + 'px)';
    //         element.style.marginBottom = marginBottom + 'px';
    //     });
    // });

    const parallaxHeroImage = document.querySelector('.parallax-hero-image');
    if (parallaxHeroImage) {
        window.addEventListener('scroll', function () {
            var scrollPosition = window.scrollY;
            var scale = 1 + (scrollPosition * 0.0003);
            parallaxHeroImage.style.transform = 'scale(' + scale + ')';
            parallaxHeroImage.style.transform = 'scale(' + scale + ')';
            parallaxHeroImage.style.opacity = 1 - (scrollPosition * 0.0015);
        });
    }


    const parallaxSlow = document.querySelector('.parallax-slow');
    if (parallaxSlow) {

        if (window.innerWidth > 768) { // Check if the screen width is greater than 768px (not mobile)
            window.addEventListener('scroll', function () {
                var scrollPosition = window.scrollY;
                var newBackgroundSize = 'cover';
                if (scrollPosition > 0) {
                    var scaleFactor = 1 + (scrollPosition * 0.0002);
                    newBackgroundSize = 'calc(100% + ' + (scrollPosition * 0.2) + 'px)';
                    // Apply the new background size to the element
                    parallaxSlow.style.backgroundSize = newBackgroundSize;
                    // Adjust container height based on scroll position
                    var container = parallaxSlow;
                    container.style.height = (container.offsetHeight - (scrollPosition * 2)) + 'px';

                    // Reduce scale and opacity on scroll
                    var scale = 1 - (scrollPosition * 0.0003);
                    var opacity = 1 - ((scrollPosition - 100) * 0.002);
                    // opacity = 1;
                    parallaxSlow.style.transformOrigin = 'left';
                    parallaxSlow.style.transform = 'translateY(' + (scrollPosition * 0.3) + 'px)';
                    parallaxSlow.style.opacity = opacity;
                    parallaxSlow.style.backgroundSize = newBackgroundSize;
                    var container = parallaxSlow;
                    container.style.height = (container.offsetHeight - (scrollPosition * 2)) + 'px';
                    parallaxSlow.style.opacity = opacity;
                    // Apply the new background size to the element
                    parallaxSlow.style.backgroundSize = newBackgroundSize;
                    // Adjust container height based on scroll position
                    var container = parallaxSlow;
                    container.style.height = (container.offsetHeight - (scrollPosition * 2)) + 'px';
                }

            });
        }
    }



    // Fade in elements on scroll
    const fadeElements = document.querySelectorAll('.fade-element');
    const fadeOptions = {
        threshold: 0.33,
        rootMargin: '-100px 0px 100px 0px', // Adjust the root margin as needed
    };

    const fadeObserver = new IntersectionObserver(function (entries, observer) {
        entries.forEach((entry, index) => {
            const fadeDelay = entry.target.getAttribute('data-fade-delay');
            const timeoutDelay = fadeDelay ? parseInt(fadeDelay) : index * 50;
            setTimeout(() => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('fade-in');
                    entry.target.classList.remove('fade-out');
                } else {
                    // entry.target.classList.remove('fade-in');
                    // entry.target.classList.add('fade-out');
                }
            }, timeoutDelay);
        });
    }, fadeOptions);

    fadeElements.forEach(element => {
        fadeObserver.observe(element);
    });


    // Animation d'entrée des mots
    const wordsInAnimation = (target) => {
        $(target).each(function () {
            const words = $(this).text().split(' ');
            console.log(words);
            words.forEach((word, index) => {
                $(word).css('transition-delay', index * 0.2 + 's');
            });
        });
    };

    // Call the animation function with the target element
    wordsInAnimation('.your-target-element');
    // Appeler l'animation d'entrée des mots
    wordsInAnimation();


    $(window).scroll(function () {
        if ($(window).width() > 768) {
            var scroll = $(window).scrollTop();
            var maxScroll = $(window).height() * 0.15;
            var maxWidth = 1330
            var newWidth = 1000 + (scroll * 0.5);

            // console.log(scroll)
            if (scroll < 10) {
                maxWidth = 1150;
            } 

            if (newWidth > 1330) {
                newWidth = 1330;
            }
            $('#wrapper-navbar.sticky-top').css({
                'max-width': maxWidth + 'px',
                'transition': 'max-width 0.5s ease-in-out'
            });
        }
    });


    // Function to shake the button
    function shakeButton() {
        $('.btn-shake').addClass('shake');
        setTimeout(function() {
            $('.btn-shake').removeClass('shake');
        }, 500); // Remove the shake class after 500ms
    }

    // Add CSS for the shake animation
    $('<style>')
        .prop('type', 'text/css')
        .html(`
        @keyframes shake {
            0% { transform: rotate(0deg); }
            10% { transform: rotate(-1deg); }
            20% { transform: rotate(1deg); }
            30% { transform: rotate(0deg); }
            40% { transform: rotate(1deg); }
            50% { transform: rotate(-1deg); }
            60% { transform: rotate(0deg); }
            70% { transform: rotate(-1deg); }
            80% { transform: rotate(1deg); }
            90% { transform: rotate(0deg); }
            100% { transform: rotate(-1deg); }
        }
        .shake {
            animation: shake 0.5s;
            animation-iteration-count: 1;
        }
        `)
        .appendTo('head');

    // Set interval to shake the button every 10 seconds
    setInterval(shakeButton, 5000);
    


});


